import React, { Component } from "react";
import LoaderAnim from "../../animation/loader.json";
import LottieAnimate from "./LottieAnimation";

class Loader extends Component {
  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <LottieAnimate
            name={"lottie-anim-loader"}
            options={{
              loop: true,
              autoplay: true,
              animationData: LoaderAnim,
            }}
            height={"400px"}
            width={"400px"}
          />
        </div>
      </div>
    );
  }
}

export default Loader;
