import React, { Component } from "react";
import Header from "./components/Header";
import C1 from "./components/C1";
import C2 from "./components/C2";
import C4 from "./components/C4";
import C5 from "./components/C5";
import C3 from "./components/C3";
import C9 from "./components/C9";
import C6 from "./components/C6";
import C8 from "./components/C8";
import C7 from "./components/C7";
import axios from "axios";
import { unflatten } from "flat";
import Loader from "./components/Loader";
import Vkyc from "./components/vkyc";
import { Helmet } from "react-helmet";
import { config } from "../config/api";
import BrandVoucher from "./components/brand-voucher";

const unflattenTemplateItems = (templateItems) => {
  let content = {};
  content = templateItems.reduce(
    (agg, cur) => ({ ...agg, [cur.key]: cur.value }),
    {}
  );
  content = unflatten(content);
  return content;
};

const makeAssetsByKey = (assets) => {
  let content = {};
  content = assets.reduce(
    (agg, cur) => ({ ...agg, [cur.key]: cur.value.data.attributes }),
    {}
  );
  return content;
};

export const getWebsiteConfig = (config) => {
  let websiteConfig = {};
  config?.map((item) => {
    websiteConfig = {
      ...websiteConfig,
      [item.attributes.page]: {
        assets: makeAssetsByKey(item.attributes.assets),
        config: unflattenTemplateItems(item.attributes.template_items),
      },
    };
  });
  return websiteConfig;
};
export default class App extends Component {
  state = {
    websiteConfig: null,
  };
  componentDidMount() {
    const endPoint = `/strapi/api/website-configs?populate=template_items&populate=assets.value`;
    axios
      .get(endPoint)
      .then((res) => {
        setTimeout(() => {
          this.setState({
            websiteConfig: getWebsiteConfig(res?.data?.data),
          });
        }, 2000);
      })
      .catch((err) => {});
  }

  helmet = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Junio - Pocket Money & Payment App for Students</title>
      <meta
        name="og:description"
        content="Junio is India’s most loved, 100% safe & secure payments app for digital pocket money management trusted by over 20 lakh students for their day to day payments."
      />
      <meta
        name="description"
        content="Junio is India’s most loved, 100% safe & secure payments app for digital pocket money management trusted by over 20 lakh students for their day to day payments."
      />
    </Helmet>
  );
  render() {
    if (!this.state.websiteConfig) {
      return (
        <div>
          {this.helmet}
          <Header
            overrideStyle={{
              container: {
                backgroundColor: "#8A27F7",
              },
            }}
          />
          <Loader />
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.helmet}
        <Header />
        <C1 data={this.state.websiteConfig.home?.config.hero} />
        <C2
          data={this.state.websiteConfig.home?.config.download}
          assets={this.state.websiteConfig.home?.assets}
        />
        <C3
          data={this.state.websiteConfig.home?.config.appBenefits}
          assets={this.state.websiteConfig.home?.assets}
        />
        <C4
          data={this.state.websiteConfig.home?.config.features}
          assets={this.state.websiteConfig.home?.assets}
        />
        <C5
          data={this.state.websiteConfig.home?.config.getJunio}
          assets={this.state.websiteConfig.home?.assets}
        />
        {this.state.websiteConfig.home?.config.featureVideo && (
          <C6
            data={this.state.websiteConfig.home?.config.featureVideo}
            assets={this.state.websiteConfig.home?.assets}
          />
        )}
        <C7 data={this.state.websiteConfig.home?.config.review} />
        <C8 data={this.state.websiteConfig.home?.config.qa} />
        <BrandVoucher
          data={this.state.websiteConfig.home?.config.brandVouchers}
          assets={this.state.websiteConfig.home?.assets}
        />
        <Vkyc
          data={this.state.websiteConfig.home?.config.vkyc}
          assets={this.state.websiteConfig.home?.assets}
        />
        <C9 />
      </React.Fragment>
    );
  }
}
